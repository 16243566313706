export const environment = {
  production: true,
  environmentName: 'dev',
  apiUrl: 'https://apiv2-nprd.cloud.bpifrance.fr/dev/dep/backoffice',
  defaultReceiverEmail: 'test.email.dep@gmail.com',
  issuer: 'https://mst.mcb-nprd.cloud.bpifrance.fr:443/am/oauth2/mcb',
  clientId : 'deeptech-spa',
  issuer2: 'https://authmfappd.web.bpifrance.fr',
  clientId2: 'deeptech-spa',

  redirectUrl:  `${encodeURI(window.location.origin )}`,
  
  platformUrl:'https://dev.dep-nprd.cloud.bpifrance.fr',
  mailImageUrl:"https://media.dev.dep-nprd.cloud.bpifrance.fr/strapi/backoffice/mailImage1.png",
  mailImageUrlISAM: "https://media.dev.dep-nprd.cloud.bpifrance.fr/strapi/backoffice/servicesISAM.png",
  observatoireUrl:"https://observatoire.lesdeeptech.fr/dashboard?applyDefaultFilters=true",
  insideboardMail:"test.email.dep@gmail.com",

  enableTaleezCall: false
};
